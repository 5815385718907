<template>
  <aside class="h-100">
    <div
      v-if="ambientetest"
      class="animacion-homologacion parpadea"
    >
      <font-awesome-icon
        icon="exclamation-triangle"
        style="color: orange;"
      />&nbsp;{{ ambientetest }}
    </div>
    <pz-banner />
    <el-container
      v-pz-loading.fullscreen.lock="loader.run"
      :pz-loading-text="loader.text"
      data-iframe-height
    >
      <el-main class="pz-main">
        <transition
          name="fade-transform"
          mode="out-in"
        >
          <router-view
            :key="key"
          />
        </transition>
        <el-backtop target=".pz-main" />
      </el-main>
      <!--<el-footer class="text-center pz-footer">-->
      <!--<el-row class="row justify-content-end h-100  align-items-center">-->
      <!--<el-col class="pz-font-size-sm">-->
      <!--{{ $t('footer_text', {year: (new Date()).getFullYear()}) }}-->
      <!--</el-col>-->
      <!--</el-row>-->
      <!--</el-footer>-->
    </el-container>
  </aside>
</template>

<script>

// import PzLanguage from "@/lang/PzLanguage";
import {mapGetters} from "vuex";
import PzBanner from "@/commons/titles/PzTitleBanner";

export default {
    name: 'App',
    components: {PzBanner},
    mixins: [],
    data() {
        return {
            windowHeight: 0,
            ambientetest: process.env.VUE_APP_AMBIENTE_TEST
        };
    },
    computed: {
        ...mapGetters([
            'loader',
            'location',
        ]),
        key() {
            return this.$route.path
        }
    },
    inject: ['app'],
    provide: function () {
        return {
            app: this.app,
        }
    },

    created() {

    },
    methods: {},


}

</script>

