export function lazyLoad(view) {
    return () => import(`@/views/documentacion/${view}.vue`)
}

export default [

    {
        path: "code",
        component: lazyLoad('ContinuarDoc'),
        name: "DocumentacionAdd",
        title: "Documentacion",
        hidden: true,
        props: true,
        meta: {title: 'Subir Documentación', hidden: true},

    },
    {
        path: "firmar/:loan/finalizado",
        component: lazyLoad('FinalFirma'),
        name: "FinFirma",
        hidden: true,
        props: true,
        meta: {title: 'views.documentacion.vale.banner', hidden: true},

    },
    {
        path: "firmar/:loan/rechazo",
        component: lazyLoad('FadRechazo'),
        name: "FadRechazo",
        hidden: true,
        props: true,
        meta: {title: 'views.documentacion.vale.banner', hidden: true},

    },
    {
        path: "firmar/:loan/contrato",
        component: lazyLoad('Vale'),
        name: "CodeSingDoc",
        hidden: true,
        props: true,
        meta: {title: 'views.documentacion.vale.banner', hidden: true},

    },
    {
        path: "firmar/:loan/contrato/:type",
        component: lazyLoad('Vale'),
        name: "FirmaContrato",
        hidden: true,
        props: true,
        meta: {title: 'views.documentacion.vale.banner', hidden: true},

    },
    {
        path: "actualizar/:loan/requeridas",
        component: lazyLoad('DocumentacionExterna'),
        name: "AddDocumentacion",
        title: "Documentacion",
        hidden: true,
        props: true,
        meta: {title: 'Subir Documentación', hidden: true},

    },
    {
        path: "actualizar/:loan/final",
        component: lazyLoad('FinalDoc'),
        name: "FinalDoc",
        title: "Documentacion Finalizada",
        hidden: true,
        props: true,
        meta: {title: 'Subir Documentación', hidden: true},
    },

]



