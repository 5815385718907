
module.exports = {
    //Local=>localStorage,Cookies=>Cookies,
    storage: 'LocalStorage',
    default_lang: 'es',
    // options...
    devServer: {
        disableHostCheck: false
    },

    title: 'Formulario de crédito',

    /**
     * @type {boolean} true | false
     * @description Whether show the settings right-panel
     */
    showSettings: false,

    /**
     * @type {boolean} true | false
     * @description Whether fix the header
     */
    fixedHeader: true,

    /**
     * @type {boolean} true | false
     * @description Whether show the logo in sidebar
     */
    sidebarLogo: true,

    /**
     * @type {string | array} 'production' | ['production', 'development']
     * @description Need show err logs component.
     * The default is only used in the production env
     * If you want to also use it in dev, you can pass ['production', 'development']
     */
    errorLog: 'production',
    filters: {
        capitalize: {
            onlyFirstLetter: false
        },
        number: {
            format: '000000.00',
            thousandsSeparator: '.',
            decimalSeparator: ','
        },
        bytes: {
            decimalDigits: 2
        },
        percent: {
            decimalDigits: 2,
            multiplier: 100
        },
        currency: {
            symbol: '$',
            decimalDigits: 2,
            thousandsSeparator: '.',
            decimalSeparator: ',',
            symbolOnLeft: true,
            spaceBetweenAmountAndSymbol: false,
            showPlusSign: false
        },
        pluralize: {
            includeNumber: false
        },
        ordinal: {
            includeNumber: false
        }
    },
}
