
const state = {
    loader: {
        run: true,
        text: ''
    },
    instances: 0,
    trigger: '',
};

const mutations = {
    SET_LOADER_UP: (state, {trigger, text}) => {
        window.scrollTo(0, 0);
        state.instances++;
        state.loader.run = state.instances > 0;
        state.trigger = trigger;
        state.loader.text = text;
    },
    SET_LOADER_DOWN: (state, {trigger}) => {
        window.scrollTo(0, 0);
        if (state.instances > 0) state.instances--
        state.loader.run = state.instances > 0;
        state.loader.text = '';
        state.trigger = trigger;
    },
};

const actions = {
    up({commit}, data) {
        commit('SET_LOADER_UP', data);
    },

    down({commit}, data) {
        commit('SET_LOADER_DOWN', data);
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
