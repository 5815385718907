export function lazyLoad(view) {
    return () => import(`@/views/productos/${view}.vue`)
}


export default [
    {
        path: "all/:type/:view?",
        name: "AllProducts",
        component: lazyLoad('ProductList'),
        props: true,
        hidden: false,
        meta: {title: 'product_selection', hidden: true},
    },
    {
        path: "testcapacidadpago",
        component: lazyLoad('Test'),
        name: "test",
        meta: {
            hidden: true,
            title: 'errors._401.header'
        }
    },
    {
        path: ":id_custom/rejected",
        component: lazyLoad('NoPodemosAyudar'),
        name: "Rejected",
        hidden: true,
        props: true,
        meta: {title: 'rejected_loan', hidden: true},
    },
    {
        path: ":id_custom/notavailable/:message",
        component: lazyLoad('Notavailable'),
        name: "Notavailable",
        hidden: true,
        props: true,
        meta: {title: 'rejected_loan', hidden: true},
    },
    {
        path: ":id_custom/timeOut",
        component: lazyLoad('ProductoCaducado'),
        hidden: true,
        name: "Producto_Caducado",
        meta: {title: 'not_available', hidden: true},
    },
    {
        path: ":id_custom/1/simulador",
        component: lazyLoad('Index'),
        props: true,
        hidden: true,
        meta: {hidden: true},
        redirect: {path: '/:alias/producto/:id_custom/1'}

    },
    {
        path: ":id_custom/:id/garante",
        component: lazyLoad('InfoGarante'),
        hidden: true,
        name: "InfoGarante",
        meta: {title: 'need_guarantee', hidden: true},
        props: true,
    },
    {
        path: ":id_custom/:id/:tipodoc?/:numerodoc?",
        component: lazyLoad('Index'),
        hidden: true,
        name: "Producto",
        props: true,
    },
    // {
    //     path: ":id_custom/:id/:loan?",
    //     component: lazyLoad('Index'),
    //     hidden: true,
    //     name: "Producto",
    //     props: true,
    // },

]

