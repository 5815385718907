const getters = {
    /**APP**/
    header: state => state.app.header,
    location: state => state.app.location,
    address: state => state.app.address,
    /**END APP**/
    /**AUTH**/
    token: state => state.auth.token,
    alias: state => state.auth.alias,
    company: state => state.auth.company,
    /**END AUTH**/
    /**LOADER**/
    loader: state => state.loader.loader,
    /**END LOADER**/
    /**PRODUCTO**/
    product: state => state.product.product,
    form_product: state => state.product.form_product,
    applicant: state => state.product.applicant,
    start_on_list: state => state.product.start_on_list,
    sucursal: state => state.product.sucursal,
    /**END PRODUCTO**/


};
export default getters
